import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['display', 'content', 'item'];

  render(evt) {
    this.displayTarget.innerHTML = evt.detail[0].body.innerHTML;
  }

  renderContent(evt) {
    this.contentTarget.innerHTML = evt.detail[0].body.innerHTML;
  }


  error(evt) {
    this.displayTarget.innerHTML = evt.detail;
  }

  update({detail, params}) {
    // remove action bar
    var element = document.getElementById("action-bar");
    if (element) {
        element.remove();
    }
    this.itemTargets.forEach(element => {
      if (element.getAttribute("data-item-id") === params.id.toString()) {
        element.querySelector("a").removeAttribute("href");
        element.querySelector("a").removeAttribute("data-remote");
        if (params.action === "approve") {
          element.style.setProperty('border-left', 'solid color(srgb 0.0176 0.5872 0.4108) 10px');
        } else {
          element.style.setProperty('border-left', 'solid rgba(220, 38, 37, 1) 10px');
        }
      }
    });
    this.contentTarget.innerHTML = detail[0].body.innerHTML;
  }
}
